import {Modal} from './util/bootstrap';

if (document.getElementById('brand-slider')) {
    import('@splidejs/splide').then(({default: Splide}) => {
        new Splide('#brand-slider', {
            type: 'loop',
            autoPlay: true,
            perPage: 4,
            gap: 50,
            pagination: false,
            breakpoints: {
                767: {
                    gap: 10,
                },
            },
        }).mount();
    });
}

if (document.getElementById('product-slider')) {
    import('@splidejs/splide').then(({default: Splide}) => {
        new Splide('#product-slider', {
            type: 'loop',
            autoPlay: true,
            perPage: 5,
            gap: 0,
            breakpoints: {
                767: {
                    perPage: 2,
                },
                1023: {
                    perPage: 4,
                },
            },
        }).mount();
    });
}

document.querySelectorAll('[data-toggle="modal"]').forEach(el => {
    new Modal(el);

    if (el.getAttribute('data-target') === '#event-reg') {
        el.addEventListener('click', () => {
            const title = document.querySelector('#event-reg [name="your-title"]');

            title.setAttribute('value', el.getAttribute('data-title'));
            title.setAttribute('readonly', 'readonly');
        });
    }
});

document.querySelector('.hamburger').addEventListener('click', e => {
    e.preventDefault();

    e.currentTarget.classList.toggle('is-active');
    document.getElementById('nav').classList.toggle('active');
});

const pswp = document.getElementById('pswp');
document.querySelectorAll('.wp-block-gallery').forEach(el => {
    const items = [];

    import('photoswipe/dist/photoswipe-ui-default').then(({default: PhotoSwipeDefault}) => {
        import('photoswipe').then(({default: PhotoSwipe}) => {
            el.querySelectorAll('a').forEach((a, i) => {
                a.addEventListener('click', ev => {
                    ev.preventDefault();

                    if (!items.length) {
                        el.querySelectorAll('a').forEach(a => {
                            const img = a.querySelector('img');

                            items.push({
                                src: a.href,
                                w: img.naturalWidth,
                                h: img.naturalHeight,
                            });
                        });
                    }

                    const options = {
                        history: true,
                        focus: false,

                        showAnimationDuration: 0,
                        hideAnimationDuration: 0,
                        index: i,
                    };

                    const gallery = new PhotoSwipe(pswp, PhotoSwipeDefault, items, options);

                    gallery.init();
                });
            })
        })
    })
});

document.getElementById('to-top').addEventListener('click', e => {
    e.preventDefault();

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
});

document.addEventListener('scroll', () => {
    const scroll = document.documentElement.scrollTop || document.body.scrollTop;

    document.getElementById('to-top').style.display = scroll > 50
        ? 'inline'
        : 'none';
});