
/* Native Javascript for Bootstrap 4 | Internal Utility Functions
----------------------------------------------------------------*/
"use strict";

// globals
var globalObject = typeof global !== 'undefined' ? global : this||window,
  DOC = document, HTML = DOC.documentElement, body = 'body', // allow the library to be used in <head>

  // Native Javascript for Bootstrap Global Object
  BSN = globalObject.BSN = {},
  supports = BSN.supports = [],

  // function toggle attributes
  dataToggle    = 'data-toggle',
  dataDismiss   = 'data-dismiss',
  dataSpy       = 'data-spy',
  dataRide      = 'data-ride',

  // components
  stringAlert     = 'Alert',
  stringButton    = 'Button',
  stringCarousel  = 'Carousel',
  stringCollapse  = 'Collapse',
  stringDropdown  = 'Dropdown',
  stringModal     = 'Modal',
  stringPopover   = 'Popover',
  stringScrollSpy = 'ScrollSpy',
  stringTab       = 'Tab',
  stringTooltip   = 'Tooltip',
  stringToast     = 'Toast',

  // options DATA API
  dataAutohide      = 'data-autohide',
  databackdrop      = 'data-backdrop',
  dataKeyboard      = 'data-keyboard',
  dataTarget        = 'data-target',
  dataInterval      = 'data-interval',
  dataHeight        = 'data-height',
  dataPause         = 'data-pause',
  dataTitle         = 'data-title',
  dataOriginalTitle = 'data-original-title',
  dataDismissible   = 'data-dismissible',
  dataTrigger       = 'data-trigger',
  dataAnimation     = 'data-animation',
  dataContainer     = 'data-container',
  dataPlacement     = 'data-placement',
  dataDelay         = 'data-delay',

  // option keys
  backdrop = 'backdrop', keyboard = 'keyboard', delay = 'delay',
  content = 'content', target = 'target', currentTarget = 'currentTarget',
  interval = 'interval', pause = 'pause', animation = 'animation',
  placement = 'placement', container = 'container',

  // box model
  offsetTop    = 'offsetTop',      offsetBottom   = 'offsetBottom',
  offsetLeft   = 'offsetLeft',
  scrollTop    = 'scrollTop',      scrollLeft     = 'scrollLeft',
  clientWidth  = 'clientWidth',    clientHeight   = 'clientHeight',
  offsetWidth  = 'offsetWidth',    offsetHeight   = 'offsetHeight',
  innerWidth   = 'innerWidth',     innerHeight    = 'innerHeight',
  scrollHeight = 'scrollHeight',   scrollWidth    = 'scrollWidth',
  height         = 'height',

  // aria
  ariaExpanded = 'aria-expanded',
  ariaHidden   = 'aria-hidden',
  ariaSelected = 'aria-selected',

  // event names
  clickEvent    = 'click',
  focusEvent    = 'focus',
  hoverEvent    = 'hover',
  keydownEvent  = 'keydown',
  keyupEvent    = 'keyup',
  resizeEvent   = 'resize', // passive
  scrollEvent   = 'scroll', // passive
  mouseHover = ('onmouseleave' in DOC) ? [ 'mouseenter', 'mouseleave'] : [ 'mouseover', 'mouseout' ],
  // touch since 2.0.26
  touchEvents = { start: 'touchstart', end: 'touchend', move:'touchmove' }, // passive
  // originalEvents
  showEvent     = 'show',
  shownEvent    = 'shown',
  hideEvent     = 'hide',
  hiddenEvent   = 'hidden',
  closeEvent    = 'close',
  closedEvent   = 'closed',
  slidEvent     = 'slid',
  slideEvent    = 'slide',
  changeEvent   = 'change',

  // other
  getAttribute           = 'getAttribute',
  setAttribute           = 'setAttribute',
  hasAttribute           = 'hasAttribute',
  createElement          = 'createElement',
  appendChild            = 'appendChild',
  innerHTML              = 'innerHTML',
  getElementsByTagName   = 'getElementsByTagName',
  preventDefault         = 'preventDefault',
  getBoundingClientRect  = 'getBoundingClientRect',
  querySelectorAll       = 'querySelectorAll',
  getElementsByCLASSNAME = 'getElementsByClassName',
  getComputedStyle       = 'getComputedStyle',  

  indexOf      = 'indexOf',
  parentNode   = 'parentNode',
  length       = 'length',
  toLowerCase  = 'toLowerCase',
  Transition   = 'Transition',
  Duration     = 'Duration',
  Webkit       = 'Webkit',
  style        = 'style',
  push         = 'push',
  tabindex     = 'tabindex',
  contains     = 'contains',

  active     = 'active',
  showClass  = 'show',
  collapsing = 'collapsing',
  disabled   = 'disabled',
  loading    = 'loading',
  left       = 'left',
  right      = 'right',
  top        = 'top',
  bottom     = 'bottom',

  // tooltip / popover
  tipPositions = /\b(top|bottom|left|right)+/,

  // modal
  modalOverlay = 0,
  fixedTop = 'fixed-top',
  fixedBottom = 'fixed-bottom',

  // transitionEnd since 2.0.4
  supportTransitions = Webkit+Transition in HTML[style] || Transition[toLowerCase]() in HTML[style],
  transitionEndEvent = Webkit+Transition in HTML[style] ? Webkit[toLowerCase]()+Transition+'End' : Transition[toLowerCase]()+'end',
  transitionDuration = Webkit+Duration in HTML[style] ? Webkit[toLowerCase]()+Transition+Duration : Transition[toLowerCase]()+Duration,

  // set new focus element since 2.0.3
  setFocus = function(element){
    element.focus ? element.focus() : element.setActive();
  },

  // class manipulation, since 2.0.0 requires polyfill.js
  addClass = function(element,classNAME) {
    element.classList.add(classNAME);
  },
  removeClass = function(element,classNAME) {
    element.classList.remove(classNAME);
  },
  hasClass = function(element,classNAME){ // since 2.0.0
    return element.classList[contains](classNAME);
  },

  // selection methods
  getElementsByClassName = function(element,classNAME) { // returns Array
    return [].slice.call(element[getElementsByCLASSNAME]( classNAME ));
  },
  queryElement = function (selector, parent) {
    var lookUp = parent ? parent : DOC;
    return typeof selector === 'object' ? selector : lookUp.querySelector(selector);
  },
  getClosest = function (element, selector) { //element is the element and selector is for the closest parent element to find
    // source http://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
    var firstChar = selector.charAt(0), selectorSubstring = selector.substr(1);
    if ( firstChar === '.' ) {// If selector is a class
      for ( ; element && element !== DOC; element = element[parentNode] ) { // Get closest match
        if ( queryElement(selector,element[parentNode]) !== null && hasClass(element,selectorSubstring) ) { return element; }
      }
    } else if ( firstChar === '#' ) { // If selector is an ID
      for ( ; element && element !== DOC; element = element[parentNode] ) { // Get closest match
        if ( element.id === selectorSubstring ) { return element; }
      }
    }
    return false;
  },

  // event attach jQuery style / trigger  since 1.2.0
  on = function (element, event, handler, options) {
    options = options || false;
    element.addEventListener(event, handler, options);
  },
  off = function(element, event, handler, options) {
    options = options || false;
    element.removeEventListener(event, handler, options);
  },
  one = function (element, event, handler, options) { // one since 2.0.4
    on(element, event, function handlerWrapper(e){
      handler(e);
      off(element, event, handlerWrapper, options);
    }, options);
  },
  // determine support for passive events
  supportPassive = (function(){
    // Test via a getter in the options object to see if the passive property is accessed
    var result = false;
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: function() {
          result = true;
        }
      });
      one(globalObject, 'testPassive', null, opts);
    } catch (e) {}

    return result;
  }()),
  // event options
  // https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
  passiveHandler = supportPassive ? { passive: true } : false,
  // transitions
  getTransitionDurationFromElement = function(element) {
    var duration = supportTransitions ? globalObject[getComputedStyle](element)[transitionDuration] : 0;
    duration = parseFloat(duration);
    duration = typeof duration === 'number' && !isNaN(duration) ? duration * 1000 : 0;
    return duration; // we take a short offset to make sure we fire on the next frame after animation
  },
  emulateTransitionEnd = function(element,handler){ // emulateTransitionEnd since 2.0.4
    var called = 0, duration = getTransitionDurationFromElement(element);
    duration ? one(element, transitionEndEvent, function(e){ !called && handler(e), called = 1; })
             : setTimeout(function() { !called && handler(), called = 1; }, 17);
  },
  bootstrapCustomEvent = function (eventName, componentName, related) {
    var OriginalCustomEvent = new CustomEvent( eventName + '.bs.' + componentName);
    OriginalCustomEvent.relatedTarget = related;
    this.dispatchEvent(OriginalCustomEvent);
  },

  // tooltip / popover stuff
  getScroll = function() { // also Affix and ScrollSpy uses it
    return {
      y : globalObject.pageYOffset || HTML[scrollTop],
      x : globalObject.pageXOffset || HTML[scrollLeft]
    }
  },
  styleTip = function(link,element,position,parent) { // both popovers and tooltips (target,tooltip,placement,elementToAppendTo)
    var elementDimensions = { w : element[offsetWidth], h: element[offsetHeight] },
        windowWidth = (HTML[clientWidth] || DOC[body][clientWidth]),
        windowHeight = (HTML[clientHeight] || DOC[body][clientHeight]),
        rect = link[getBoundingClientRect](),
        scroll = parent === DOC[body] ? getScroll() : { x: parent[offsetLeft] + parent[scrollLeft], y: parent[offsetTop] + parent[scrollTop] },
        linkDimensions = { w: rect[right] - rect[left], h: rect[bottom] - rect[top] },
        isPopover = hasClass(element,'popover'),
        topPosition, leftPosition,

        arrow = queryElement('.arrow',element),
        arrowTop, arrowLeft, arrowWidth, arrowHeight,

        halfTopExceed = rect[top] + linkDimensions.h/2 - elementDimensions.h/2 < 0,
        halfLeftExceed = rect[left] + linkDimensions.w/2 - elementDimensions.w/2 < 0,
        halfRightExceed = rect[left] + elementDimensions.w/2 + linkDimensions.w/2 >= windowWidth,
        halfBottomExceed = rect[top] + elementDimensions.h/2 + linkDimensions.h/2 >= windowHeight,
        topExceed = rect[top] - elementDimensions.h < 0,
        leftExceed = rect[left] - elementDimensions.w < 0,
        bottomExceed = rect[top] + elementDimensions.h + linkDimensions.h >= windowHeight,
        rightExceed = rect[left] + elementDimensions.w + linkDimensions.w >= windowWidth;

    // recompute position
    position = (position === left || position === right) && leftExceed && rightExceed ? top : position; // first, when both left and right limits are exceeded, we fall back to top|bottom
    position = position === top && topExceed ? bottom : position;
    position = position === bottom && bottomExceed ? top : position;
    position = position === left && leftExceed ? right : position;
    position = position === right && rightExceed ? left : position;

    // update tooltip/popover class
    element.className[indexOf](position) === -1 && (element.className = element.className.replace(tipPositions,position));

    // we check the computed width & height and update here
    arrowWidth = arrow[offsetWidth]; arrowHeight = arrow[offsetHeight];

    // apply styling to tooltip or popover
    if ( position === left || position === right ) { // secondary|side positions
      if ( position === left ) { // LEFT
        leftPosition = rect[left] + scroll.x - elementDimensions.w - ( isPopover ? arrowWidth : 0 );
      } else { // RIGHT
        leftPosition = rect[left] + scroll.x + linkDimensions.w;
      }

      // adjust top and arrow
      if (halfTopExceed) {
        topPosition = rect[top] + scroll.y;
        arrowTop = linkDimensions.h/2 - arrowWidth;
      } else if (halfBottomExceed) {
        topPosition = rect[top] + scroll.y - elementDimensions.h + linkDimensions.h;
        arrowTop = elementDimensions.h - linkDimensions.h/2 - arrowWidth;
      } else {
        topPosition = rect[top] + scroll.y - elementDimensions.h/2 + linkDimensions.h/2;
        arrowTop = elementDimensions.h/2 - (isPopover ? arrowHeight*0.9 : arrowHeight/2);
      }
    } else if ( position === top || position === bottom ) { // primary|vertical positions
      if ( position === top) { // TOP
        topPosition =  rect[top] + scroll.y - elementDimensions.h - ( isPopover ? arrowHeight : 0 );
      } else { // BOTTOM
        topPosition = rect[top] + scroll.y + linkDimensions.h;
      }
      // adjust left | right and also the arrow
      if (halfLeftExceed) {
        leftPosition = 0;
        arrowLeft = rect[left] + linkDimensions.w/2 - arrowWidth;
      } else if (halfRightExceed) {
        leftPosition = windowWidth - elementDimensions.w*1.01;
        arrowLeft = elementDimensions.w - ( windowWidth - rect[left] ) + linkDimensions.w/2 - arrowWidth/2;
      } else {
        leftPosition = rect[left] + scroll.x - elementDimensions.w/2 + linkDimensions.w/2;
        arrowLeft = elementDimensions.w/2 - ( isPopover ? arrowWidth : arrowWidth/2 );
      }
    }

    // apply style to tooltip/popover and its arrow
    element[style][top] = topPosition + 'px';
    element[style][left] = leftPosition + 'px';

    arrowTop && (arrow[style][top] = arrowTop + 'px');
    arrowLeft && (arrow[style][left] = arrowLeft + 'px');
  };

/* Native Javascript for Bootstrap 4 | Modal
-------------------------------------------*/

// MODAL DEFINITION
// ===============
var Modal = function(element, options) { // element can be the modal/triggering button

  // the modal (both JavaScript / DATA API init) / triggering button element (DATA API)
  element = queryElement(element);

    // strings
    var component = 'modal',
      staticString = 'static',
      modalTrigger = 'modalTrigger',
      paddingRight = 'paddingRight',
      modalBackdropString = 'modal-backdrop',
      isAnimating = 'isAnimating',
      // determine modal, triggering element
      btnCheck = element[getAttribute](dataTarget)||element[getAttribute]('href'),
      checkModal = queryElement( btnCheck ),
      modal = hasClass(element,component) ? element : checkModal;

    if ( hasClass(element, component) ) { element = null; } // modal is now independent of it's triggering element

  if ( !modal ) { return; } // invalidate

  // set options
  options = options || {};

  this[keyboard] = options[keyboard] === false || modal[getAttribute](dataKeyboard) === 'false' ? false : true;
  this[backdrop] = options[backdrop] === staticString || modal[getAttribute](databackdrop) === staticString ? staticString : true;
  this[backdrop] = options[backdrop] === false || modal[getAttribute](databackdrop) === 'false' ? false : this[backdrop];
  this[animation] = hasClass(modal, 'fade') ? true : false;
  this[content]  = options[content]; // JavaScript only

  // set an initial state of the modal
  modal[isAnimating] = false;
  
  // bind, constants, event targets and other vars
  var self = this, relatedTarget = null,
    bodyIsOverflowing, scrollBarWidth, overlay, overlayDelay, modalTimer,

    // also find fixed-top / fixed-bottom items
    fixedItems = getElementsByClassName(HTML,fixedTop).concat(getElementsByClassName(HTML,fixedBottom)),

    // private methods
    getWindowWidth = function() {
      var htmlRect = HTML[getBoundingClientRect]();
      return globalObject[innerWidth] || (htmlRect[right] - Math.abs(htmlRect[left]));
    },
    setScrollbar = function () {
      var bodyStyle = globalObject[getComputedStyle](DOC[body]),
          bodyPad = parseInt((bodyStyle[paddingRight]), 10), itemPad;
      if (bodyIsOverflowing) {
        DOC[body][style][paddingRight] = (bodyPad + scrollBarWidth) + 'px';
        modal[style][paddingRight] = scrollBarWidth+'px';
        if (fixedItems[length]){
          for (var i = 0; i < fixedItems[length]; i++) {
            itemPad = globalObject[getComputedStyle](fixedItems[i])[paddingRight];
            fixedItems[i][style][paddingRight] = ( parseInt(itemPad) + scrollBarWidth) + 'px';
          }
        }
      }
    },
    resetScrollbar = function () {
      DOC[body][style][paddingRight] = '';
      modal[style][paddingRight] = '';
      if (fixedItems[length]){
        for (var i = 0; i < fixedItems[length]; i++) {
          fixedItems[i][style][paddingRight] = '';
        }
      }
    },
    measureScrollbar = function () { // thx walsh
      var scrollDiv = DOC[createElement]('div'), widthValue;
      scrollDiv.className = component+'-scrollbar-measure'; // this is here to stay
      DOC[body][appendChild](scrollDiv);
      widthValue = scrollDiv[offsetWidth] - scrollDiv[clientWidth];
      DOC[body].removeChild(scrollDiv);
      return widthValue;
    },
    checkScrollbar = function () {
      bodyIsOverflowing = DOC[body][clientWidth] < getWindowWidth();
      scrollBarWidth = measureScrollbar();
    },
    createOverlay = function() {
      var newOverlay = DOC[createElement]('div');
      overlay = queryElement('.'+modalBackdropString);

      if ( overlay === null ) {
        newOverlay[setAttribute]('class', modalBackdropString + (self[animation] ? ' fade' : ''));
        overlay = newOverlay;
        DOC[body][appendChild](overlay);
      }
      modalOverlay = 1;
    },
    removeOverlay = function() {
      overlay = queryElement('.'+modalBackdropString);
      if ( overlay && overlay !== null && typeof overlay === 'object' ) {
        modalOverlay = 0;
        DOC[body].removeChild(overlay); overlay = null;
      }
    },
    // triggers
    triggerShow = function() {
      setFocus(modal);
      modal[isAnimating] = false;
      bootstrapCustomEvent.call(modal, shownEvent, component, relatedTarget);

      on(globalObject, resizeEvent, self.update, passiveHandler);
      on(modal, clickEvent, dismissHandler);
      on(DOC, keydownEvent, keyHandler);      
    },
    triggerHide = function() {
      modal[style].display = '';
      element && (setFocus(element));
      bootstrapCustomEvent.call(modal, hiddenEvent, component);

      (function(){
        if (!getElementsByClassName(DOC,component+' '+showClass)[0]) {
          resetScrollbar();
          removeClass(DOC[body],component+'-open');
          overlay && hasClass(overlay,'fade') ? (removeClass(overlay,showClass), emulateTransitionEnd(overlay,removeOverlay))
          : removeOverlay();

          off(globalObject, resizeEvent, self.update, passiveHandler);
          off(modal, clickEvent, dismissHandler);
          off(DOC, keydownEvent, keyHandler);
        }
      }());
      modal[isAnimating] = false;
    },
    // handlers
    clickHandler = function(e) {
      if ( modal[isAnimating] ) return;

      var clickTarget = e[target];
      clickTarget = clickTarget[hasAttribute](dataTarget) || clickTarget[hasAttribute]('href') ? clickTarget : clickTarget[parentNode];
      if ( clickTarget === element && !hasClass(modal,showClass) ) {
        modal[modalTrigger] = element;
        relatedTarget = element;
        self.show();
        e[preventDefault]();
      }
    },
    keyHandler = function(e) {
      if ( modal[isAnimating] ) return;

      if (self[keyboard] && e.which == 27 && hasClass(modal,showClass) ) {
        self.hide();
      }
    },
    dismissHandler = function(e) {
      if ( modal[isAnimating] ) return;
      var clickTarget = e[target];

      if ( hasClass(modal,showClass) && ( clickTarget[parentNode][getAttribute](dataDismiss) === component
          || clickTarget[getAttribute](dataDismiss) === component
          || clickTarget === modal && self[backdrop] !== staticString ) ) {
        self.hide(); relatedTarget = null;
        e[preventDefault]();
      }
    };

  // public methods
  this.toggle = function() {
    if ( hasClass(modal,showClass) ) {this.hide();} else {this.show();}
  };
  this.show = function() {
    if ( hasClass(modal,showClass) || modal[isAnimating] ) {return}

    clearTimeout(modalTimer);
    modalTimer = setTimeout(function(){
      modal[isAnimating] = true;
      bootstrapCustomEvent.call(modal, showEvent, component, relatedTarget);

      // we elegantly hide any opened modal
      var currentOpen = getElementsByClassName(DOC,component+' '+showClass)[0];
      if (currentOpen && currentOpen !== modal) {
        modalTrigger in currentOpen && currentOpen[modalTrigger][stringModal].hide();
        stringModal in currentOpen && currentOpen[stringModal].hide();
      }

      if ( self[backdrop] ) {
        !modalOverlay && !overlay && createOverlay();
      }

      if ( overlay && !hasClass(overlay,showClass) ) {
        overlay[offsetWidth]; // force reflow to enable trasition
        overlayDelay = getTransitionDurationFromElement(overlay);
        addClass(overlay, showClass);
      }

      setTimeout( function() {
        modal[style].display = 'block';

        checkScrollbar();
        setScrollbar();

        addClass(DOC[body],component+'-open');
        addClass(modal,showClass);
        modal[setAttribute](ariaHidden, false);

        hasClass(modal,'fade') ? emulateTransitionEnd(modal, triggerShow) : triggerShow();
      }, supportTransitions && overlay && overlayDelay ? overlayDelay : 1);
    },1);
  };
  this.hide = function() {
    if ( modal[isAnimating] || !hasClass(modal,showClass) ) {return}

    clearTimeout(modalTimer);
    modalTimer = setTimeout(function(){
      modal[isAnimating] = true;    
      bootstrapCustomEvent.call(modal, hideEvent, component);
      overlay = queryElement('.'+modalBackdropString);
      overlayDelay = overlay && getTransitionDurationFromElement(overlay);

      removeClass(modal,showClass);
      modal[setAttribute](ariaHidden, true);

      setTimeout(function(){
        hasClass(modal,'fade') ? emulateTransitionEnd(modal, triggerHide) : triggerHide();
      }, supportTransitions && overlay && overlayDelay ? overlayDelay : 2);
    },2)
  };
  this.setContent = function( content ) {
    queryElement('.'+component+'-content',modal)[innerHTML] = content;
  };
  this.update = function() {
    if (hasClass(modal,showClass)) {
      checkScrollbar();
      setScrollbar();
    }
  };

  // init
  // prevent adding event handlers over and over
  // modal is independent of a triggering element
  if ( !!element && !(stringModal in element) ) {
    on(element, clickEvent, clickHandler);
  }
  if ( !!self[content] ) { self.setContent( self[content] ); }
  if (element) { element[stringModal] = self; modal[modalTrigger] = element; }
  else { modal[stringModal] = self; }
};

// DATA API
supports[push]( [ stringModal, Modal, '['+dataToggle+'="modal"]' ] );
; export {Modal};